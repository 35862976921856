import * as React from 'react'
import Layout from "../components/layout/layout";
import {Link} from "gatsby";

const AboutPage = () => {
    return (
        <Layout pageTitle="About Us" pageKeywords="about us, belthosting" pageDescription="About us page">
            <div className="row">
                <div className="col-md-2"/>
                <div className="col-md-8">
                    <div className="page">
                        <div className="title">
                            About Us
                        </div>
                        <div className="content">
                            <p>Short History</p>
                            <ul>
                                <li>
                                    Welcome to BeltHosting.com - Web hosting organization, which was established 15
                                    March 2011.
                                </li>
                                <li>
                                    Our servers are highly engineered, and our focus is on speed, energy and stability.
                                </li>
                                <li>
                                    Our sister service, xShellz, is a Linux shell service offering both free and paid
                                    accounts for people who want to learn basic Linux command operations, programming,
                                    how to run IRC bots (such as eggdrops or python based bots), or even for people who
                                    want to take the leap and try running an IRC network of their own on our own
                                    customized IRCd's.
                                </li>
                            </ul>
                            <p>A web-hosting network</p>
                            <ul>
                                <li>BeltHosting is a web-hosting service located in europe.</li>
                                <li>BeltHosting is a great hosting platform for your website, business development or
                                    even small websites like blogs.
                                </li>
                            </ul>
                            <p>Operations & Live Support</p>
                            <ul>
                                <li>Our hosting server is located in Florida (DimeNoc datacenter).</li>
                                <li>All our web-servers use cPanel/WHM and their addons such as Softaculous
                                    (Auto-Installer script).
                                </li>
                                <li>We have <Link to="/livesupport">live support</Link>.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-2"/>
            </div>
        </Layout>
    )
}
export default AboutPage
